@import url(./vendor/normalize.css);
@import url(./vendor/fonts/fonts.css);

#root {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
}

:root {
  --blue: #348ff3;
  --white: #ffffff;
  --orange: #ff824b;
  --violet: #7472eb;
  --rose: #f95273;
  --dark-blue: #064b72;
  --gray: #aeaeae;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: 10px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
button,
label {
  cursor: pointer;
}

input,
textarea,
button,
select,
img,
span,
a,
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@media screen and (max-height: 552px) {
  html {
    font-size: 8px;
  }
}

.empty-block {
  height: 40px;
  width: 100%;
  flex-shrink: 0;
}
