.payment {
  padding: 16px 30px 64px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--dark-blue);
  height: 100%;
  box-sizing: border-box;
}
.payment__methods {
  width: 100%;
  margin-top: 20%;
}
.payment__value {
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  height: 44px;
  box-sizing: border-box;
  border: 2px solid #064b72;
  border-radius: 16px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  width: 90%;
  line-height: 1.5rem;
}
.payment__button-box {
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.payment__value-data {
  font-weight: 900;
  font-size: 1.7rem;
}
