.copy-to-clipboard {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1.5px solid #348ff3;
  border-radius: 13px;
  padding: 8.5px 17px;
  color: var(--blue);
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2.1rem;
  word-wrap: break-word;
  text-decoration: underline;
  position: relative;
  margin: 15px 0;
  background-color: transparent;
  text-align: left;
  box-sizing: border-box;
}
.copy-to-clipboard.android {
  /* padding-left: 10px !important; */
  /* min-height: 150px !important; */
}
.copy-to-clipboard__key {
  font-size: 1.7vh;
  line-height: 2.5vh;
  width: 95%;
}

.copy-to-clipboard__key_selectable {
  user-select: auto;
}
.copy-to-clipboard__copy-icon {
  width: 15px;
  position: absolute;
  right: 5px;
  top: 5px;
  filter: invert(0.35) sepia(1) saturate(5) hue-rotate(175deg);
}

.copy-to-clipboard__copy-icon.hidden {
  display: none;
}

.copy-to-clipboard__key-input {
  width: 95%;
  height: 100%;
  resize: vertical;
  font-size: 1.7vh;
  line-height: 2.5vh;
  color: var(--blue);
  border: none;
  outline: none;
  overflow: hidden;
  caret-color: transparent;
  cursor: default;
}

.referral__copy-to-clipboard {
  height: auto;
  color: #7472eb;
  border-color: #7472eb;
  margin: 0 2vh 0 0;
  justify-content: flex-start;
  width: 100%;
  padding-left: 10px;
}
.referral__copy-to-clipboard .copy-to-clipboard__key {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  /* font-size: 1.4rem; */
  font-size: 3.8vw;
  font-family: "Actay Wide", sans-serif;
  text-align: start;
}

.referral__copy-to-clipboard .copy-to-clipboard__copy-icon {
  width: 18px;
  height: 18px;
  top: 50%;
  stroke: red;
  transform: translate3d(0, -50%, 0);
  right: 10px;
  filter: invert(45%) sepia(66%) saturate(929%) hue-rotate(210deg)
    brightness(93%) contrast(99%);
}

.options__copy-to-clipboard {
  height: auto;
  background-color: var(--orange);
  color: var(--white);
  border-color: transparent;
  margin: 0 2vh 0 0;
  justify-content: flex-start;
  width: 100%;
  padding-left: 10px;
}

.options__box-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.options__copy-to-clipboard .copy-to-clipboard__key {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  /* font-size: 1.4rem; */
  font-size: 3.8vw;
  font-family: "Actay Wide", sans-serif;
  text-align: start;
}

.options__copy-to-clipboard .copy-to-clipboard__copy-icon {
  width: 18px;
  height: 18px;
  top: 50%;
  stroke: red;
  transform: translate3d(0, -50%, 0);
  right: 10px;
  filter: invert(1);
  /* filter: invert(45%) sepia(66%) saturate(929%) hue-rotate(210deg)
    brightness(93%) contrast(99%); */
}
.instruction__copy-to-clipboard {
  /* max-width: 287px; */
}
/* .instruction__copy-to-clipboard.android {
  padding-right: 30px !important;
} */
.instruction__copy-to-clipboard .copy-to-clipboard__copy-icon {
  height: 25px;
  width: 25px;
}
.copy-to-clipboard.options {
  border: none;
  background-color: var(--orange);
  height: 40px;
  box-sizing: border-box;
  padding: 11px;
  align-items: flex-start;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.copy-to-clipboard.options .copy-to-clipboard__copy-icon {
  filter: invert(1);
  width: 25px;
  top: 17%;
}
.copy-to-clipboard.high .copy-to-clipboard__copy-icon {
  top: 35%;
}
.copy-to-clipboard.options .copy-to-clipboard__key {
  font-size: 1.4rem;
  line-height: 1.4rem;
  color: var(--white);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
  text-decoration: underline;
}
.copy-to-clipboard__popup {
  position: absolute;
  left: -2px;
  bottom: 0;
  transform: translateY(30%);
  width: calc(100% + 3px);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.copy-to-clipboard__popup.referral__copy-to-clipboard {
  color: var(--white);
  background-color: #7472eb;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute !important;
  transform: none;
  width: 100%;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: none;
  border-radius: 10px;
  /* padding: 5px 15px; */
  margin-bottom: 0;
}
.copy-to-clipboard__popup.options__copy-to-clipboard {
  color: var(--orange);
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute !important;
  transform: none;
  width: 100%;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: none;
  border-radius: 10px;
  /* padding: 5px 15px; */
  margin-bottom: 0;
}
.referral__copy-to-clipboard.high {
  padding-bottom: 2.3rem;
  margin-bottom: 1.5rem;
}
.referral__copy-to-clipboard.high.referral__copy-to-clipboard {
  /* padding-bottom: 0; */
  height: 40%;
  margin-top: 1.5vh;
}

.copy-to-clipboard__popup-text.options__copy-to-clipboard {
  font-size: 3.7vw;
  color: var(--orange);
  background-color: transparent;
  height: auto;
  font-family: "Inter", sans-serif;
}

.copy-to-clipboard__popup-text.referral__copy-to-clipboard {
  font-size: 3.7vw;
  color: white;
  height: auto;
  font-family: "Inter", sans-serif;
}
.copy-to-clipboard__popup.referral__copy-to-clipboard
  .copy-to-clipboard__popup-icon {
  position: absolute;
  width: auto;
  margin: 0;
  height: 70%;
  right: 5px;
  filter: invert(100%);
}
.copy-to-clipboard__popup.options {
  color: var(--orange);
  background-color: var(--white);
  display: flex;
  border-radius: 13px;
  padding: 5px 15px;
  margin-bottom: 0;
  height: auto;
  width: 100%;
  left: 0;
}
.options__copy-to-clipboard.copy-to-clipboard__popup-icon {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: transparent;
  right: 0;
  filter: invert(59%) sepia(82%) saturate(1243%) hue-rotate(327deg)
    brightness(101%) contrast(101%);
}
.copy-to-clipboard.options.high {
  padding-bottom: 3rem;
  margin-bottom: 1.5rem;
  height: auto;
}
.copy-to-clipboard.options.high .copy-to-clipboard__copy-icon {
  top: 10%;
}

.copy-to-clipboard__popup.instruction__copy-to-clipboard {
  color: var(--white);
  background-color: var(--blue);
  display: flex;
  border-radius: 13px;
  padding: 5px 15px;
  height: auto;
  transform: translateY(35%);
}
.copy-to-clipboard__popup.instruction__copy-to-clipboard
  .copy-to-clipboard__popup-icon {
  position: absolute;
  right: 5px;
  filter: invert(98%) sepia(6%) saturate(21%) hue-rotate(275deg)
    brightness(106%) contrast(100%);
}
.copy-to-clipboard.instruction__copy-to-clipboard.high {
  margin-bottom: 1.5rem;
  min-height: 99px;
}
.copy-to-clipboard.instruction__copy-to-clipboard.high
  .copy-to-clipboard__copy-icon {
  top: 5px;
}
