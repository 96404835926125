.support {
  padding: 16px 45px 90px 45px;
  color: var(--white);
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.support__text-container {
  box-sizing: border-box;
  padding-bottom: 20%;
}
.support__text {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
  font-family: 'Actay Wide', sans-serif;
}

.support__link {
  text-decoration: underline;
  cursor: pointer;
}
