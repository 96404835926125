.current-tariff-widget {
  border: 1px solid var(--blue);
  display: flex;
  width: 100%;
  border-radius: 12px;
  font-family: 'Actay', sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: var(--blue);
  padding: 5px 10px;
  box-sizing: border-box;
  margin-top: 2vh;
}

/* .current-tariff-widget.tariffes-list-widget {
  margin-top: 7px;
} */

.end {
  display: none;
}

@media screen and (max-height: 630px) {
  .current-tariff-widget {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
}

@media screen and (max-height: 520px) {
  .current-tariff-widget {
    font-size: 1.2rem;
  }
}

@media screen and (max-height: 510px) {
  .current-tariff-widget {
    font-size: 1.2rem;
    line-height: 1.3rem;
    margin-top: 0px;
  }
}
