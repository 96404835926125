.app-button {
  box-sizing: border-box;
  width: 95%;
  min-height: 40px;
  border-radius: 12px;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-size: 2rem;
  line-height: 2.5rem;
  padding: 0;
  font-weight: 700;
  font-family: "Actay Wide", sans-serif;
  border: 2px solid transparent;
}
.app-button.blue {
  color: var(--blue);
}
.app-button.white {
  color: var(--white);
}
.app-button.violet {
  color: var(--violet);
}
.app-button.rose {
  color: var(--rose);
}
.app-button.dark-blue {
  color: var(--dark-blue);
}
.app-button.orange {
  color: var(--orange);
}

.app-button.primary {
  background-color: var(--white);
  border-color: transparent;
}
.app-button.secondary {
  background-color: transparent;
  border: 2px solid #fff;
}
.app-button.narrow {
  margin: 0;
  width: 48%;
  font-size: 1.4rem;
}
.app-button.wide {
  width: 100%;
}
.app-button.small-text {
  font-size: 1.2rem;
  line-height: 1.2rem;
  padding: 0 20px;
  min-height: 35px;
  box-sizing: border-box;
}
.app-button.border-transparent {
  border-color: transparent;
}
.app-button.border-blue {
  border: 2px solid #348ff3;
}

.app-button.instruction_button {
  width: 100%;
  min-height: 0;
  padding-top: 1vh;
  padding-bottom: 1vh;
  border-radius: 1.2vh;
  font-size: 2.5vh;
  border-width: 1px;
  box-shadow: 0px 4px 4px 0px #00000025;
  margin-bottom: 5px;
}

.app-button.intro_button {
  width: 100%;
  padding-top: 1vh;
  padding-bottom: 1vh;
  border-radius: 1.2vh;
  font-size: 2.5vh;
  border-width: 1px;
  box-shadow: 0px 4px 4px 0px #00000025;
  margin-bottom: 5px;
}

.app-button.border-white {
  border: 2px solid #fff;
}
.app-button.bg-blue {
  background-color: var(--blue);
}
.app-button.bg-orange {
  background-color: var(--orange);
}
.app-button.bg-dark-blue {
  background-color: var(--dark-blue);
}
.app-button.bg-violet {
  background-color: var(--violet);
}
.app-button.bg-white {
  background-color: var(--white);
}
.app-button.support-disabled {
  color: var(--gray);
  pointer-events: none;
}
.app-button.disabled {
  pointer-events: none;
  cursor: not-allowed;
  background-color: var(--gray);
}
.app-button.disabled-opts {
  pointer-events: none;
  cursor: not-allowed;
}
.app-button.margin-bottom {
  margin: 0 0 15px 0;
}
.app-button.margin-top {
  margin: 10px 0 0 0;
}

.app-button.fit {
  margin-top: 0px;
  color: var(--white);
  background-color: var(--orange);
  border-color: transparent;
}

.app-button.own {
  min-height: 45px;
  margin: 0px auto 0 auto;
  background-color: var(--violet);
  color: var(--white);
}

.app-button.own-pay {
  min-height: 45px;
  margin: 1px auto 0 auto;
  background-color: var(--violet);
  color: var(--white);
}

.app-button.own.own-smart {
  background: linear-gradient(180deg, #7472eb 0%, #f95273 100%);
  box-shadow: none;
  border: none;
}

.app-button.own-pay.own-smart {
  background: linear-gradient(180deg, #7472eb 0%, #f95273 100%);
  box-shadow: none;
  border: none;
}

.hidden {
  display: none;
}

.app-button.bonus {
  border-color: transparent;
  background-color: var(--violet);
  color: var(--white);
}

.app-button.nolimit {
  margin-top: 0;
  color: var(--white);
  background-color: var(--rose);
  border-color: transparent;
}
.app-button.gift__submit {
  width: 90%;
  height: 48px;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 12px 16px;
  border: none;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.5rem;
  color: var(--orange);
}

.app-button.fit-smart {
  /* margin-top: 10px; */
  color: var(--white);
  border-color: transparent;
  background: linear-gradient(180deg, #ff824b 0%, #ffbe30 100%);
}
.app-button.nolimit-smart {
  background: linear-gradient(180deg, #f95273 0%, #ffbe30 100%);
  margin-top: 0;
  color: var(--white);
  border-color: transparent;
}

@media screen and (max-height: 650px) {
  .app-button.fit,
  .app-button.nolimit,
  .app-button.free {
    margin-top: 0;
  }
}

@media screen and (max-height: 650px) and (max-width: 370px) {
  .app-button.own {
    /* min-height: 40px; */
  }
}

@media screen and (max-height: 600px) {
  .app-button.own {
    /* min-height: 40px; */
  }

  .app-button.own-pay {
    min-height: 40px;
  }
}
@media screen and (max-height: 540px) {
  .app-button.fit {
    min-height: 30px;
  }
}

@media screen and (max-height: 520px) {
  .app-button {
    min-height: 35px;
  }
}

@media screen and (max-height: 570px) {
  .app-button.own {
    /* min-height: 35px; */
  }
  .app-button.own-pay {
    min-height: 35px;
  }
}

@media screen and (max-height: 580px) {
  .app-button.own {
    margin-top: 0;
  }

  .app-button.own-pay {
    margin-top: 0;
  }
}
