.data-item {
  display: flex;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 0 37px;
  box-sizing: border-box;
  flex-grow: 1;
}
.data-item:first-of-type {
  margin-top: 5%;
}
.data-item__link {
  color: var(--white);
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

.data-item__arrow {
  width: 10px;
  height: 10px;
  transform: rotate(225deg);
  background-color: transparent;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  position: absolute;
  right: 0;
  top: 40%;
}
.data-item__content {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.data-item__arrow_rotated {
  transform: rotate(45deg);
}
.data-item__button {
  position: relative;
  border: none;
  outline: none;
  box-sizing: border-box;
  background-color: transparent;
  color: var(--white);
  font-weight: 700;
  font-size: 2rem;
  width: 100%;
  text-align: start;
  height: 100%;
  min-height: 60px;
  padding: 0 25px 0 0;
  font-family: "Actay Wide", sans-serif;
}
.data-item__button:active {
  user-select: none;
}
.data-item__text {
  font-family: "Inter", sans-serif;
  margin: 0;
  font-weight: 400;
  padding: 15px 0 25px 0;
  box-sizing: border-box;
  white-space: pre-wrap;
}
.data-item_expanded {
  height: auto;
}
.data-item__button-text {
  padding: 15px 0;
}
.data-item_expanded .data-item__button {
  max-height: 80px;
}
.data-list-faq .data-item__button {
  font-size: 1.6rem;
}
