.balance {
  display: flex;
  flex-direction: column;
  color: var(--white);
  padding: 16px 45px;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}
.balance__row {
  margin: auto 0;
}
.balance__row-input-box {
  position: relative;
}
.balance__title {
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.2rem;
  font-family: 'Actay Wide', sans-serif;
}
.balance__current {
  height: 85px;
  background: #ffffff;
  border: 1.5px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  color: var(--orange);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-bottom: 8px;
}
.balance__current-value {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.2rem;
  font-family: 'Actay Wide', sans-serif;
}
.balance__current-text {
  font-family: 'inter', sans-serif;
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.7rem;
}
.balance__box {
  display: flex;
  justify-content: space-between;
}
.balance__item {
  width: 48%;
}
.balance__button {
  width: 100%;
  color: var(--white);
  font-size: 1.2rem;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  padding: 0;
  font-family: 'Inter', sans-serif;
  user-select: none;
}
.balance__button span {
  margin-left: 5px;
}
.balance__subtitle {
  margin: 10px 0;
  font-weight: 700;
  font-size: 1.4rem;
  font-family: 'Actay Wide', sans-serif;
}
.balance__sum-input {
  width: 100%;
  height: 48px;
  border: 1px solid #ffffff;
  border-radius: 12px;
  background-color: var(--orange);
  padding: 12px 16px;
  box-sizing: border-box;
  color: var(--white);
  font-weight: 700;
  font-size: 2rem;
  outline: none;
  position: relative;
  appearance: none;
}

.balance__sum-input:focus .balance__sum-input::placeholder {
  opacity: 0.4;
}
.balance__row:last-of-type {
  position: relative;
}
.balance__sum-cash-value {
  position: absolute;
  width: 20px;
  height: 20px;
  font-weight: 700;
  font-size: 2rem;
  right: 5px;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  color: var(--white);
}
.balance__sum-input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.balance__button-box {
  margin: 11px 0 17px 0;
  display: flex;
  height: 48px;
  justify-content: space-between;
}
.balance__button-secondary {
  font-size: 1.7rem;
  width: 48%;
  color: var(--white);
  border: 1px solid #fff;
  background-color: transparent;
  margin: 0;
}
.balance__button-primary {
  font-size: 1.7rem;
  width: 48%;
  background-color: var(--white);
  border-color: transparent;
  color: var(--orange);
  margin: 0;
}
.balance__tips {
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin: 0;
}
.balance__tips-link {
  text-decoration: underline;
  cursor: pointer;
}
.balance__row-input-box.focused {
  position: absolute;
  top: 0;
  left: 35px;
  height: 100%;
  width: calc(100% - 70px);
  padding-top: 15%;
  box-sizing: border-box;
  background-color: var(--orange);
}
.balance__input-container {
  position: relative;
}
