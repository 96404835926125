.tariffes-template {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: 100%;
  justify-content: space-between;
}
.tariffes-template.free {
  color: var(--violet);
}

.tariffes-template.own-persons {
  justify-content: space-between;
  height: 100%;
}
.tariffes-template.own {
  justify-content: space-between;
  height: 100%;
}
.tariffes-template.own-country {
  justify-content: flex-start;
}
.tariffes_full_price {
  margin-top: 2vh;
  font-weight: 700;
  font-size: 2vh;
  color: rgba(249, 82, 115, 1);
  font-family: "Inter", sans-serif;
}
.robo_balance_hint_value {
  font-weight: 300;
  font-size: 3.5vw;
  margin-left: 3vw;
  color: white;
  font-family: "Inter", sans-serif;
}
.robo_balance_block {
  width: 95%;
  padding: 0.25vh;
  display: flex;
  flex-direction: column;
  margin-bottom: 2vh;
  border-radius: 2vh;
  background-color: rgba(249, 82, 115, 1);
}
.robo_balance_block_fit {
  background-color: rgba(255, 130, 75, 1) !important;
}
.robo_balance_block_own {
  background-color: #7472eb !important;
}
.robo_balance_amount_block {
  padding: 1vh 2vh 1vh 2vh;
  display: flex;
  border-radius: 2vh;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}
.robo_balance_check_block {
  padding: 2vh 2vh 2vh 2vh;
  width: 100%;
  display: flex;
  border-radius: 2vh;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.robo_balance_check {
  box-shadow: none;
  accent-color: white;
  border: none;
}

input[type="checkbox"] {
  width: 2vh !important;
  height: 2vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 5px; */
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: 0px solid white;
  box-shadow: none;
  font-size: 0.8em;
  text-align: center;
  line-height: 1em;
  border-radius: 3px;
  background: white;
}
input[type="checkbox"].check-fit:checked:after {
  content: "✓";
  font-size: 1.5vh;
  font-weight: bold;
  color: rgba(255, 130, 75, 1);
}
input[type="checkbox"].check-nolimit:checked:after {
  content: "✓";
  font-size: 1.5vh;
  font-weight: bold;
  color: #f95273;
}
input[type="checkbox"].check-own:checked:after {
  content: "✓";
  font-size: 1.5vh;
  font-weight: bold;
  color: #7472eb;
}
.robo_balance_check::after {
  box-shadow: none;
}
.robo_balance_check:checked::before {
  box-shadow: none;
}
.robo_balance_amount_block_label {
  font-weight: 300;
  font-size: 1.3rem;
  color: rgba(249, 82, 115, 1);
  font-family: "Inter", sans-serif;
}
.robo_balance_amount_block_value {
  font-weight: 700;
  font-size: 2rem;
  color: rgba(249, 82, 115, 1);
  font-family: "Actay wide", sans-serif;
}
.tariffes_full_price_fit {
  color: rgba(255, 130, 75, 1) !important;
  margin-top: 2vh !important;
}
.tariffes_full_price_fit_label {
  color: rgba(255, 130, 75, 1) !important;
}
.tariffes_full_price_own {
  color: #7472eb !important;
  margin-top: 1vh !important;
}
.tariffes_full_price_own_label {
  color: #7472eb !important;
}
.tariffes_full_price_own_value {
  color: #7472eb !important;
}
.tariffes_full_price_own_hidden {
  color: white !important;
}
.first_buy_hint {
  margin-top: 1vh;
  margin-bottom: 1vh;
  display: block;
  width: 90%;
  font-weight: 700;
  font-size: 3vw;
  color: rgba(249, 82, 115, 1);
  font-family: "Actay wide", sans-serif;
}
.tariffes-template__content {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 0.6rem 0;
  /* height: 90%;
  overflow-y: scroll; */
  border-radius: 15px;
  /* height: 50%; */
  overflow-y: scroll;
  scrollbar-color: transparent;
}

.tariffes-template__content.own.own2 {
  border-radius: 0;
  /* overflow: hidden; */
  overflow-y: scroll;
  overflow-x: hidden;
  height: 1000%;
}

.tariffes-template__content.own-persons {
  /* height: 50%; */
  justify-content: space-around;
}

.tariffes-template__content.own.own1 {
  /* overflow-y: scroll; */
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  /* height: 90%; */
  margin: 0;
}

.tariffes-template__content.own.own0 {
  /* overflow-y: scroll; */
  overflow-x: hidden;
}

.tariffes-template__content.own-country {
  margin: 0 0 2.3rem 0;
}

.tariffes-template__speed {
  border: 1px solid var(--blue);
  width: 100%;
  border-radius: 12px;
  font-family: "Actay", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: var(--blue);
  padding: 5px 10px;
  box-sizing: border-box;
  margin-top: 25px;
  /* margin-bottom: 2vh; */
}

.tariffes-template__content-secondary {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tariffes-template__content-secondary.own-country {
  height: 100%;
  justify-content: flex-end;
}

.tariffes-template__content-button {
  width: 100%;
  border: none;
  background-color: #f8f9fe;
  border-radius: 16px;
  font-family: "Actay Wide";
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2rem;
  padding: 10px 24px;
  box-sizing: border-box;
  text-align: start;
  margin-bottom: 10px;
  position: relative;
}
.tariffes-template__content-button.hidden {
  opacity: 0;
}
.tariffes-template__content-button.bonus {
  color: var(--violet);
}
.tariffes-template__content-button.fit {
  color: var(--orange);
  margin-bottom: 20px;
}
.tariffes-template__content-button.nolimit {
  color: var(--rose);
}
.tariffes-template__content-button-arrow {
  width: 10px;
  height: 10px;
  transform: rotate(-135deg);
  background-color: transparent;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  position: absolute;
  right: 35px;
}
.tariffes-template__content-button-arrow.bonus {
  border-color: var(--violet);
}
.tariffes-template__content-button-arrow.fit {
  border-color: var(--orange);
}
.tariffes-template__content-button-arrow.nolimit {
  border-color: var(--rose);
}

.tariffes-template__gurantee-fit {
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: 400;
  line-height: 2.1rem;
  color: var(--orange);
}

.tariffes-template__gurantee-nolimit {
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: 400;
  line-height: 2.1rem;
  color: var(--rose);
}

.tariffes-template__gurantee-own {
  margin-top: 5px;
  font-size: 1.1rem;
  /* margin-top: 1rem; */
  font-weight: 400;
  line-height: 2.1rem;
  color: var(--violet);
  color: white;
}

.tariffes-template__amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 800;
  font-size: 1.3rem;
  width: 80%;
  margin: 0 auto;
  background-color: var(--rose);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  color: var(--white);
  min-height: 5rem;
  padding: 0 15px;
}

.tariffes-template__amount.fit {
  background-color: var(--orange);
}

.tariffes-template__amount.smart-fit {
  background: linear-gradient(180deg, #ff824b 0%, #ffbe30 100%);
}

.tariffes-template__amount.smart-nolimit {
  background: linear-gradient(180deg, #f95273 0%, #ffbe30 100%);
}

.tariffes-template__amount-full {
  text-decoration: line-through;
  margin-left: 7px;
}
.tariffes-template__amount-full.hidden {
  display: none;
}
@media screen and (min-height: 720px) {
  .tariffes-template__content.own.own1 {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    height: 90%;
  }
}

/* 
@media screen and (max-height: 670px) {
  .tariffes-template__content-button.fit {
    margin-bottom: 5px;
  }
  .tariffes-template__content {
    margin: 2.1rem 0 1.1rem 0;
  }
  .tariffes-template__content-button.fit {
    margin-top: 1.1rem;
  }
}

@media screen and (max-height: 640px) {
  .tariffes-template__content {
    margin: 1.1rem 0 1.1rem 0;
  }
}



@media screen and (max-height: 610px) {
  .tariffes-template__content-button.fit {
    margin-bottom: 0.3rem;
    margin-top: 1rem;
  }
  .tariffes-template__content {
    margin: 2.1rem 0 1rem 0;
  }
}

@media screen and (max-height: 685px) {
  .tariffes-template__content {
    height: 90%;
    overflow-y: scroll;
  }
}

@media screen and (max-height: 530px) {
  .tariffes-template__content-button.fit {
    margin-bottom: 0;
  }
} */
