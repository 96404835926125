.smart-toggle {
    display: flex;
    width: 85%;
    font-family: 'Actay', sans-serif;
    background-color: #F8F9FE;
    border-radius: 16px;
    padding: 10px 16px 11px 23px;
    margin-bottom: 10px;
    margin-top: 1rem;
	align-items: center;
	justify-content: space-around;
}

.smart-toggle.own {
	width: 90%;
}

.smart-toggle__desc {
    width: 70%;
    color: #AEAEAE;
}

.smart-toggle__desc.fit {
    color: #FF824B;
}

.smart-toggle__desc.nolimit{
    color: #F95273;
}

.smart-toggle__desc.gift-color {
	color: #FF824B;
}

.smart-toggle__desc.own {
	color: var(--violet)
}

.smart-toggle__desc-title {
	margin-top: 10px;
    font-size: 1.7rem;
    font-weight: 700;
    line-height: 2.1rem;
}

.smart-toggle__desc-title.gift-color {
	margin-top: 0;
}

.smart-toggle__desc-text {
    font-size: 1rem;
}

.smart-toggle__desc-text.hidden {
	display: none;
}

.smart-toggle__desc-text-underline {
	border: none;
    text-decoration: underline;
	color: #AEAEAE;
	background-color: #F8F9FE;
}

.smart-toggle__desc-text-underline.own {
	color: var(--violet);
}

.smart-toggle__desc-text-underline.nolimit {
	color: var(--rose);
}

.smart-toggle__desc-text-underline.fit {
	color: var(--orange);
}

.smart-toggle__desc-text-underline.hidden {
	display: none;
}

.smart-toggle__slider{
	height: 0;
	width: 0;
	visibility: hidden;
}

.smart-toggle__label {
	box-sizing: border-box;
	cursor: pointer;
	text-indent: -9999px;
	min-width: 56px;
	height: 30px;
	background: grey;
	display: block;
	border-radius: 50px;
	position: relative;
}

.smart-toggle__slider:not(:checked) + .smart-toggle__label:after {
	transition: 0.1s ease-in-out;
}

.smart-toggle__label:after {
	content: '';
	position: absolute;
	box-sizing: border-box;
	top: 1px;
	left: 1px;
	min-width: 28px;
	height: 28px;
	background: #fff;
	border-radius: 90px;
	/* transition: 0.1s ease-in-out; */
}

.smart-toggle__slider:checked + .smart-toggle__label.fit{
	background: linear-gradient(180deg, #FF824B 0%, #FFBE30 100%);
}
.smart-toggle__slider:checked + .smart-toggle__label.nolimit{
    background: linear-gradient(180deg, #F95273 0%, #FFBE30 100%);
}

.smart-toggle__slider:checked + .smart-toggle__label.gift-color{
	background: linear-gradient(180deg, #FF824B 0%, #FFBE30 100%);;
}

.smart-toggle__slider:checked + .smart-toggle__label.gift{
	background: linear-gradient(180deg, #FF824B 0%, #FFBE30 100%);;
}

.smart-toggle__slider:checked + .smart-toggle__label:after {
	left: calc(100% - 1px);
	transform: translateX(-100%);
	transition: 0.1s ease-in-out;
}

/* .smart-toggle__label:active:after {
	width: 50px;
} */

.smart-toggle__desc-text.gift{
	display: none
}


.smart-toggle__motion {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	min-width: 56px;
	height: 30px;
	background: grey;
	border-radius: 50px;
	padding-left: 2px;
	padding-right: 1px;
}

.smart-toggle__motion.fit {
	background: linear-gradient(180deg, #FF824B 0%, #FFBE30 100%);
}

.smart-toggle__motion.nolimit {
	background: linear-gradient(180deg, #F95273 0%, #FFBE30 100%);
}

.smart-toggle__motion.gift-color {
	background: linear-gradient(180deg, #FF824B 0%, #FFBE30 100%);
}
.smart-toggle__motion.gift {
	background: linear-gradient(180deg, #FF824B 0%, #FFBE30 100%);
}

.smart-toggle__motion.own {
	background: linear-gradient(180deg, #7472EB 0%, #F95273 100%);
}

.smart-toggle__motion-btn {
	width: 27px;
	height: 27px;
	background: #fff;
	border-radius: 90px;
}

@media screen and (max-height: 720px) {
	.smart-toggle.nolimit {
		margin-bottom: 20px;
		max-height: 100px;
	}

	.smart-toggle.fit {
		margin-bottom: 15px;
		max-height: 100px;
	}
}

@media screen and (max-height: 595px) {
	.smart-toggle  {
	  padding: 5px 11px 6px 18px;
	}
  
	.smart-toggle__desc-title {
	  font-size: 1.5rem;
	  margin-top: 7px;
	}

	.smart-toggle__motion {
		min-width: 51px;
		height: 27px;
	  }
	
	  .smart-toggle__motion-btn {
		width: 24px;
		  height: 24px;
	  }
  }


@media screen and (max-height: 535px) {
	.smart-toggle {
		max-height: 40px;
	}
	.smart-toggle__motion {
		width: 50px;
		height: 27px;
	}
	.smart-toggle__motion-btn {
		width: 27px;
		height: 24px;
	}
}

