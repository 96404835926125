.message-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 16px 5% 60px 5%;
}
.message-us__text_main,
.message-us__text_secondary {
  font-family: 'Actay Wide', sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.8rem;
  color: var(--white);
  width: 90%;
  margin: 0;
}
.message-us__text_main {
  margin: 10% 0 10% 0;
}
.message-us__text_secondary {
  margin-bottom: auto;
}
.message-us__link {
  color: var(--white);
  text-decoration: underline;
  cursor: pointer;
}
