.form-label {
  width: 100%;
  display: flex;
  padding: 16px;
  background-color: gray;
  position: relative;
  color: var(--white);
  border-radius: 16px;
  box-sizing: border-box;
}
.form-label__content {
  margin-left: 25px;
  display: flex;
  flex-direction: column;
}

.form-label__content-flex {
  display: flex;
}
.form-label__title {
  font-size: 1.4rem;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  margin: 0;
}
.form-label__title_moved {
  margin-bottom: 6px;
}
.form-label__text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.4rem;
}
.form-label__radio-input {
  appearance: none;
}
.form-label__radio-input::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  border-radius: 50px;
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: 2px solid #fff;
  box-sizing: border-box;
}
.form-label__radio-input:checked::before {
  background-color: var(--white);
  border: 5px solid #348ff3;
}
.form-label__value {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.form-label__value-main {
  text-align: right;
  font-size: 1.6rem;
  font-weight: 700;
}
.tariff-item-own {
  background-color: var(--violet);
  /* margin-bottom: 12px; */
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  padding: 10px 16px;
}

.own-first  .tariff-item-own {
  padding: 16px;
}




.tariff-item-own.own-limited {
  background-color: var(--gray);
}

.tariff-item-own.own-limited.own-limited-message {
  background: linear-gradient(180deg, #AEAEAE 0%, rgba(116, 114, 235, 0.58) 100%);
}

.tariff-item-own-list {
  background-color: var(--violet);
  margin-bottom: 12px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);

}
.tariff-item-own-list:last-of-type {
  margin-bottom: 0;
}

.tariff-item-fit {
  background-color: var(--orange);
  margin-bottom: 12px;
}

.tariff-item-fit.smart {
  background: linear-gradient(180deg, #FF824B 0%, #FFBE30 100%);
  margin-bottom: 12px;
}

.tariff-item-nolimit {
  background-color: var(--rose);
  margin-bottom: 12px;
}

.tariff-item-nolimit.smart{
  background: linear-gradient(180deg, #F95273 0%, #FFBE30 100%);
  margin-bottom: 12px;
}


.form-label-item-gift {
  background-color: var(--orange);
  margin-top: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.form-label-item-gift.colored {
  background: linear-gradient(180deg, #FF824B 0%, #FFBE30 100%);
}
.form-label-item-gift .form-label__content {
  width: 100%;
}
.form-label-item-gift .form-label__value {
  width: 40%;
  text-align: end;
}

.form-label__disc {
  display: flex;
  gap: 5px
}

.form-label__value-secondary {
  display: none;
}
.form-label-item-gift_discounted .form-label__value-main {
  text-decoration: line-through;
  font-size: 1.2rem;
}
.form-label-item-gift_discounted .form-label__value-secondary {
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
}
.form-label.tariff-item-fit {
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 15px;
  transition: box-shadow 0.3s ease;
  padding: 10px 16px;
}
.tariff-item-fit .form-label__content {
  width: 100%;
}
.tariff-item-own .form-label__content {
  width: 100%;
}

.tariff-item-own-list .form-label__content {
  width: 100%;
}

.tariff-item-own .form-label__text-box {
  width: 80%;
}
.tariff-item-fit .form-label__value-secondary {
  display: inline-block;
  font-size: 10px;
  font-family: 'Inter', sans-serif;
  width: 100%;
  text-align: right;
  margin-top: 3px;
}
.tariff-item-fit .form-label__value {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.tariff-item-own .form-label__value {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.tariff-item-own-list .form-label__value {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.tariff-item-own .form-label__value-secondary {
  width: 100%;
}
.form-label__value-main_crossed {
  text-decoration: line-through;
}
.form-label.tariff-item-nolimit:first-of-type,
.form-label.tariff-item-fit:first-of-type {
  margin-top: 0;
}
.form-label.tariff-item-fit:last-of-type,
.form-label.tariff-item-nolimit:last-of-type {
  margin-bottom: 0;
}
.form-label.tariff-item-nolimit {
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 10px;
  padding: 10px 16px;
}
.tariff-item-nolimit .form-label__content {
  width: 100%;
}
.tariff-item-nolimit .form-label__value-secondary {
  display: inline-block;
  font-size: 10px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  width: 100%;
  text-align: right;
  margin-top: 3px;
}

.tariff-item-own .form-label__value-secondary {
  display: inline-block;
  font-size: 10px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  width: 100%;
  text-align: right;
  margin-top: 3px;
} 
.tariff-item-nolimit .form-label__text-box {
  width: 60%;
}
.tariff-item-nolimit .form-label__value {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.form-label__method {
  color: var(--dark-blue);
  background-color: transparent;
  border: 2px solid #064b72;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 10px;
}
.form-label__method .form-label__radio-input::before {
  border-color: var(--dark-blue);
  border-radius: 5px;
  width: 14px;
  height: 14px;
  right: 16px;
  cursor: pointer;
}
.form-label__method .form-label__radio-input:checked::before {
  border: 7px solid #064b72;
}
.form-label__method .form-label__content {
  margin: 0;
  width: 100%;
}
.form-label__method .form-label__text-secondary {
  font-size: 1.5rem;
  text-decoration: underline;
}
.form-label__method .form-label__title {
  font-family: 'Actay', sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  text-decoration: none !important;
}

.form-label.recomended {
  box-shadow: 0px 4px 4px rgba(249, 82, 115, 0.8);
}
.form-label__method.checkbox {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;
  padding: 16px;
}

.form-label__radio-input.checkbox {
  position: absolute;
  right: 0;
}
.form-label__discounted-items {
  position: absolute;
  height: 2rem;
  width: 100%;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  box-sizing: border-box;
}
.form-label-item-gift_discounted .form-label-discount {
  color: var(--orange);
}
.form-label-item-gift_discounted .form-label__value-main_default {
  display: none;
}

/* @media screen and (max-height: 652px) {
  .tariff-item-own {
    padding: 10px;
    min-height: 50px;
  }

}

@media screen and (max-height: 610px) {
  .form-label-item-gift {
    padding: 10px;
    min-height: 50px;
  }

  .form-label__value-main {
    font-size: 1.2rem;
  }
}

@media screen and (max-height: 600px) {
  .tariffes__content-fit {
    margin: 5px 0;
  }
  .form-label {
    padding: 10px;
    min-height: 50px;
  }
}

@media screen and (max-width: 385px){
  .form-label {
    padding: 13px;
  }
}

@media screen and (max-width: 355px){
  .form-label {
    padding: 10px;
  }
} */
