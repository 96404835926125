.tariffes-list {
  /* margin: auto 0; */
}
.tariffes__popup-container {
  height: 90%;
  overflow-y: scroll;
  position: relative;
}
.tariffes__popup-overlay {
  width: 100%;
  height: 90%;
  top: 0;
  left: 0;
  position: absolute;
  pointer-events: none;
  /* background: linear-gradient(
    to top,
    #fff 1%,
    transparent 7%,
    transparent 95%,
    #fff 100%
  ); */
  z-index: 10;
}
