.back-button {
  height: 40px;
  text-decoration: none;
  color: var(--white);
  align-self: flex-start;
  align-items: center;
  padding: 0 20px;
  position: absolute;
  outline: none;
  border: 1px solid transparent;
  background-color: transparent;
  width: calc(100% - 40px);
  justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  box-sizing: border-box;
  left: 20px;
  top: 2vh;
  z-index: 6;
}
/* .back-button.own-first {
  height: 26px;
} */

.back-button__corner {
  width: 10px;
  height: 10px;
  transform: rotate(-45deg);
  background-color: transparent;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  position: absolute;
  left: 0;
  transition: all 0.3s linear;
  border-color: #006ffd;
}
.back-button__title {
  font-size: 1.2rem;
  font-weight: 500;
  font-family: 'Actay', sans-serif;
  line-height: 1.2rem;
  color: #006ffd;
  display: flex;
  max-width: 25%;
  width: 100%;
  text-align: left;
}
.back-button__section-title {
  max-width: 100%;
  width: 100%;
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  text-align: center;
  color: var(--blue);
  justify-self: center;
}
.back-button.white {
  color: var(--white);
  /* top: 1.5vh; */
}
.back-button.moved-right {
  /* margin-left: 30px;
  width: 70%; */
}
.back-button.white .back-button__corner {
  border-color: var(--white);
}
.back-button.white .back-button__title {
  color: var(--white);
}
.back-button.white .back-button__section-title {
  color: var(--white);
}
.back-button.narrow {
  width: 80%;
  margin: 0 auto;
}
/* @media screen and (max-height: 552px) {
  .back-button {
    min-height: 60px;
  }
} */
