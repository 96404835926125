.referral {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 35px 72px 35px;
  box-sizing: border-box;
  color: var(--white);
}
.referral__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  height: 100%;
}
.referral_block {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  align-items: center;
  height: calc(4vh + 15vw);
  background-color: white;
  border-radius: 1.5vh;
  padding: 3vw 3vw 3vw 3vw;
  overflow: hidden;
}
.referral_stages_block {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: space-between;
  /* min-height: 20vh; */
  padding: 0 2vh 0 2vh;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 15px;
  margin-top: 8px;
  width: 100%;
}
.referral_stages_grid_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
}
.referral_stages_grid_cell {
  width: 10vw;
  /* height: 100%; */
  aspect-ratio: 1 !important;
  box-sizing: border-box;
  /* border: 1px solid black; */
}
.bottom_down_border {
  /* border-bottom: 2px solid white;
  border-right: 2px solid white; */
  border-bottom: 2px solid white;
  border-right: 2px solid white;
}
.bottom_border {
  border-bottom: 2px solid white;
}
.right_border {
  border-right: 2px solid white;
}
.top_border {
  border-top: 2px solid white;
}
.referral_stages_ladder_block {
  flex: 1;
  /* height: 100%; */
  /* padding: 3vh 0 3vh 0; */
  box-sizing: border-box;
  position: relative;
}
.referral_stages_text_block {
  flex: 1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 1vw;
}
.referral_stages_amount {
  font-weight: 700;
  font-family: "Actay wide", sans-serif;
  font-size: 4vw;
  left: 2.3vw;
  bottom: 4vh;
  opacity: 0.25;
}
.referral_stages_two_amount {
  font-weight: 700;
  font-family: "Actay wide", sans-serif;
  font-size: 4vw;
  left: 11.9vw;
  bottom: 10.5vh;
  position: absolute;
}
.referral_stages_three_amount {
  font-weight: 700;
  font-family: "Actay wide", sans-serif;
  font-size: 4vw;
  left: 22.3vw;
  /* bottom: 79%; */
  top: 0;
  position: absolute;
}
.referral_stages_text {
  margin-top: 0.1vh;
  margin-bottom: 0.1vh;
  font-family: "Inter", sans-serif;
  font-size: 3.5vw;
}
.cell_bottom_center_align {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 0.5vh;
}
.cell_top_center_align {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  padding-top: 1vh;
}
.referral_person_logo {
  width: 30%;
  opacity: 0.25;
}
.active_person_logo {
  opacity: 1;
}
.referral_terms_block {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  /* height: 15vh; */
  background-color: transparent;
  border: 1px solid white;
  border-radius: 2vh;
  padding: 2vh 2vh 2vh 2vh;
  margin-top: 8px;
  width: 100%;
}
.referral_terms_fields {
  display: flex;
  flex-direction: column;
}
.referral_term_line {
  margin-top: 0.1vh;
  margin-bottom: 0.1vh;
  font-family: "Inter", sans-serif;
  font-size: 3vw;
}
.referral_hint_block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 22vw;
  height: 22vw;
  max-height: 28vw;
  background-color: transparent;
  border: 1.5px solid #7472eb;
  border-radius: 1vh;
  padding: 1.5vh;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.referral_hint_block_button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 22vw;
  height: 22vw;
  max-height: 28vw;
  background-color: #7472eb;
  border: 1.5px solid #7472eb;
  border-radius: 1vh;
  padding: 1.5vh;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.referral_invite_logo {
  height: 60%;
  align-self: center;
}
.referral_ladder_logo {
  width: 30vw;
  /* margin: 4vh 2vh 2vh 0; */
  align-self: center;
}
.referral_close_invite_button {
  width: 3vh;
  height: 3vh;
  position: absolute;
  top: 2vh;
  right: 2vh;
}
.referral_close_logo {
  height: 100%;
  align-self: center;
}
.referral_heart_logo {
  height: 80%;
}
.referral_hint_title_block {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.referral_hint_title {
  font-size: 5vw;
  font-weight: 700;
  color: #7472eb;
  font-family: "Actay Wide", sans-serif;
}
.referral_hint_title_small {
  font-size: 3.5vw;
  font-weight: 700;
  margin-left: 1vw;
  padding-bottom: 0.4vw;
  color: rgba(212, 211, 255, 1);
  font-family: "Actay Wide", sans-serif;
}
.referral_invite_block {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 1.5vh;
  background-color: white;
  flex-direction: column;
  position: absolute;
  justify-content: space-between;
  pointer-events: none;
  opacity: 0;
  /* transform: translateY(50px);
  transition: transform 0.2s; */
  padding: 3.5vw 3.5vw 3.5vw 3.5vw;
  left: 0;
  box-sizing: border-box;
}
.referral_invite_block_hidden {
  transform: translateY(50px);
  transition: all 0.2s;
}
.referral_invite_block_visible {
  opacity: 1;
  pointer-events: inherit;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 1.5vh;
  background-color: white;
  flex-direction: column;
  position: absolute;
  justify-content: space-between;
  transform: translateY(0);
  transition: transform 0.2s;
}
.referral_invite_title {
  max-width: 60%;
  font-size: 3.9vw;
  color: #7472eb;
  font-family: "Inter", sans-serif;
}
.referral_hint_description {
  font-size: 2.7vw;
  color: #7472eb;
  margin-top: 1%;
  line-height: 2.7vw;
  font-family: "Inter", sans-serif;
}
.referral_hint_description_button {
  font-size: 2.7vw;
  color: white;
  margin-top: 0.5vh;
  line-height: 2.7vw;
  font-family: "Inter", sans-serif;
}
.referral_hint_detail {
  font-size: 2vw;
  color: #7472eb;
  font-family: "Inter", sans-serif;
  margin-top: 5%;
}
.referral_withdraw_button {
  background-color: #aeaeae;
  margin-bottom: 29px;
  width: 100%;
}
.referral_terms_text {
  color: white;
  font-size: 2.2vh;
  width: 80%;
  font-family: "Inter", sans-serif;
}
.referral_progress {
  width: 100%;
  margin-top: 5%;
  height: 0.6vh;
  background-color: white;
}
.referral_progress::-webkit-progress-bar {
  background-color: #d4d3ff;
  border-radius: 7px;
}
.referral_progress::-webkit-progress-value {
  background-color: #7472eb;
  border-radius: 7px;
}
.referral_progress::-moz-progress-bar {
  /* style rules */
}
.referral__title {
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.2rem;
  margin: 5px 0;
}
.referral__text-box {
  padding-top: 10%;
  width: 100%;
  font-family: "Inter", sans-serif;
}
.referral__text {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.8rem;
  margin: 0;
}
.referral__stats {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
}
.referral__stat {
  border: 1.5px solid #ffffff;
  border-radius: 12px;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.referral__stat_small {
  width: 49%;
}
.referral__stat_big {
  width: 100%;
  flex-direction: row;
  margin-top: 2%;
}
.referral__stat_big .referral__stat-value {
  width: 50%;
}
.referral__stat_big .referral__stat-text {
  margin: 0;
  width: 100%;
}
.referral__stat-value {
  font-size: 3.6rem;
  font-weight: 700;
  font-family: "Actay Wide", sans-serif;
}
.referral__stat-text {
  font-size: 10px;
  line-height: 1.1rem;
  margin: 10px 0 0 0;
  font-family: "Inter", sans-serif;
}
.referral__button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.referral__tips {
  font-size: 1.1rem;
  line-height: 1.3rem;
  margin: 0 0 20px 0;
  width: 100%;
  font-family: "Inter", sans-serif;
}

.referral__temp-text {
  font-size: 1.5rem;
  line-height: 1.3rem;
  margin: 0 0 20px 0;
  width: 100%;
  font-family: "Inter", sans-serif;
  text-align: center;
}
.referral__tips:first-of-type {
  margin: 22px 0 8px 0;
}
.referral__popup_block {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.referral__popup-title {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0;
  font-size: 1.3rem;
  white-space: pre-wrap;
}
.referral__popup-text {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  font-size: 1.3rem;
  white-space: pre-wrap;
  font-weight: normal;
  margin-bottom: 20px;
}
.referral__popup-text:last-of-type {
  margin-bottom: auto;
}
@media screen and (max-height: 650px) {
  .referral__popup-text {
    margin: 0 0 20px 0;
  }
  .referral__popup-title {
    margin: 0 0 5px 0;
  }
  .referral__popup-text:first-of-type {
    margin-top: 20px;
  }
  .referral__text-box {
    padding-top: 0;
  }
}
