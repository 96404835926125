.subscription {
  padding: 16px 30px 16px 30px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--white);
  text-align: center;
  font-family: 'Actay Wide', sans-serif;
}
.subscription__subtitle {
  margin: 0;
  font-size: 2.4rem;
  font-weight: 400;
  font-family: 'Actay', sans-serif;
}

.subscription__subtitle.hidden {
  display: none;
}
.subscription__subtitle_ended.hidden {
  display: none;
}
.subscription__title {
  margin: 14px 0 0 0;
  font-size: 3.2rem;
  font-weight: 700;
}

.subscription__sub {
  margin-top: 10px;
}
.subscription__smart {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 130px;
  font-weight: 700;
  padding: 5px;
  font-size: 1rem;
  height: 1.9rem;
  border-radius: 20px;
  margin: 10px auto 0 auto;
}
.subscription__smart.nolimit {
  background: linear-gradient(180deg, #f95273 0%, #ffbe30 100%);
}
.subscription__smart.fit {
  background: linear-gradient(180deg, #ff824b 0%, #ffbe30 100%);
}
.subscription__smart.hidden {
  display: none;
}
.subscription__text {
  margin: 12px 0 18px 0;
  font-size: 1.4rem;
  font-weight: 400;
  font-family: 'Actay', sans-serif;
}
.subscription__widget {
  border: 1.5px solid #ffffff;
  border-radius: 12px;
  padding: 10px;
  width: 49%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.subscription__widget_secondary.hidden {
  display: none;
}

.subscription__widget.nogb {
  width: 100%;
  min-height: 70px;
}
.subscription__widget-value {
  margin-top: 4px;
  font-size: 2.4rem;
  font-weight: 700;
}

.subscription__widget_flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.subscription__widgets_hidden {
  display: none;
}
.subscription__widget-box {
  display: flex;
  justify-content: space-between;
}
.subscription__widget-box.nogb {
  width: 48%;
}
.subscription__widget-box.centered {
  justify-content: center;
}
.subscription__widget-text {
  font-family: 'Actay', sans-serif;
  font-size: 10px;
  line-height: 1.1rem;
}
.subscription__widget_secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 12px;
  border: 1.5px solid #fff;
  padding: 11px 22px;
  box-sizing: border-box;
  margin-top: 5px;
}
.subscription__widget_secondary.noalgo {
  width: 48%;
  margin-top: 0;
  padding: 10px 22px;
  min-height: 70px;
}
.subscription__widget_secondary.hidden {
  display: none;
}
.subscription__widget-text_secondary {
  font-size: 10px;
  font-weight: 400;
  font-family: 'Actay', sans-serif;
}
.subscription__widget_disabled {
  display: none;
}
@media screen and (max-height: 552px) {
  .subscription {
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 375px) {
  .subscription__widget.nogb {
    min-height: 80px;
  }
}

@media screen and (max-width: 365px) {
  .subscription__widget.nogb {
    min-height: 90px;
  }
}

@media screen and (max-width: 345px) {
  .subscription__widget.nogb {
    min-height: 102px;
  }
}
