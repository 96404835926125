.preloader {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 26% 0 10% 0;
  align-items: center;
}
.preloader__image {
  width: 154px;
  height: 154px;
}
