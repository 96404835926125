.tariffes {
  padding: 60px 24px 72px 24px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tariffes.own {
  padding: 60px 24px 2vh 24px;
}

.tariffes.fit, .tariffes.nolimit {
  padding-bottom: 53px;
}

.tariffes.own.own-first {
  padding: 60px 24px 72px 24px;
}

.tariffes.nolimit {
  padding-bottom: 24px;
}

.tariffes_internal_content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.tariffes__button-box {
  display: flex;
  justify-content: space-between;
  justify-content: center;
}
.tariffes__tips {
  color: var(--blue);
  font-size: 1.1rem;
  font-family: 'Inter', sans-serif;
  margin-top: 1vh;
  text-align: center;
}
.tariffes_top_block {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.tariffes__content-secondary {
  margin-bottom: 2vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.tariffes__error {
  font-size: 1.2rem;
  color: red;
  text-align: center;
  width: 100%;
  height: 15px;
}

.tariffes__error.hidden {
  display: none;
}

@media screen and (max-height: 650px) {
  .tariffes__popup-container,
  .tariffes__popup-overlay {
    height: 85%;
  }
  .tariffes__popup-container {
    margin-bottom: 5%;
  }
  .tariffes.free,
  .tariffes.fit,
  .tariffes.nolimit {
    padding-bottom: 16px;
  }
}
@media screen and (max-height: 552px) {
  .tariffes__popup-overlay {
    height: 86%;
  }
  .tariffes__popup-container {
    margin-bottom: 0;
  }
}
