.traffic {
    position: relative;
    padding: 16px 45px;
    color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    box-sizing: border-box;
    justify-content: space-between;
}
.traffic__main {
    display: flex;
    flex-direction: column;
    font-family: "Inter", sans-serif;
}
.traffic__main-content {
    width: 100%;
    background-color: var(--white);
    height: 136px;
    border: 1.5px solid #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    box-sizing: border-box;
    color: var(--rose);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 7px 10px;
}
.traffic__main_text {
    font-size: 10px;
    line-height: 1.1rem;
    letter-spacing: -0.41px;
    text-align: center;
}
.traffic__main_value {
    font-weight: 700;
    font-size: 64px;
    font-family: "Actay Wide", sans-serif;
}
.traffic__main_value.nolimit {
    font-size: 3.6rem;
}
.traffic__main_value.empty {
    font-size: 2rem;
    height: 100%;
    display: flex;
    align-items: center;
}
.traffic__outlook {
    display: flex;
    margin: 14px 0 0 0;
    justify-content: space-between;
}
.traffic__outlook-element {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 8px;
    box-sizing: border-box;
    border: 1.5px solid #ffffff;
    border-radius: 12px;
}
.traffic__outlook-element-text {
    font-size: 1.1rem;
}
.traffic__outlook-element-value {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 2.2rem;
    font-family: "Actay Wide", sans-serif;
}
.traffic__button-box {
    display: flex;
    justify-content: space-between;
}
.traffic__tips {
    font-size: 1.2rem;
    line-height: 1.6rem;
}
.traffic__link {
    text-decoration: underline;
    cursor: pointer;
}

.traffic__history {
    border: 1px solid #fff;
    border-radius: 12px;
    margin-top: 15px;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.traffic__history-title {
    font-size: 16px;
    font-weight: 700;
    font-family: "Actay Wide", sans-serif;
}

.traffic__history-dots div {
    height: 4px;
    width: 4px;
    background-color: #fff;
    border-radius: 100%;
    margin-bottom: 3px;
}

.traffic__history-item {
    position: absolute;
    top: 10%;
    left: 5%;
    background-color: #fff;
    height: 86%;
    width: 90%;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 17px;
    transition: 0.3s ease;
    visibility: hidden;
    opacity: 0;
}
.traffic__history-item.visible {
    visibility: visible;
    opacity: 1;
}

.traffic__history-cross {
    width: 90%;
    display: flex;
    justify-content: flex-end;
}

.traffic__history-label {
    background-color: var(--rose);
    width: 90%;
    height: 48px;
    min-height: 48px;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 11px;
}

.traffic__history-label-item {
    width: 100%;
    text-align: center;
}

.traffic__history-days {
    margin-top: 11px;
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    overflow-y: auto;
    margin-bottom: 11px;
}

.traffic__history-day {
    color: var(--rose);
    font-size: 16px;
    font-weight: 500;
    border: 1px solid var(--rose);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    padding: 10px 0 14px 0;
}

.traffic__history-items {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.traffic__history-tag {
    background: var(--rose);
    font-weight: 500;
    font-size: 13px;
    line-height: 173%;
    letter-spacing: -0.03em;
    color: #fff;
    margin-left: 23px;
    padding: 3px 10px;
    border-radius: 12px;
    width: max-content;
}
