.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  position: relative;
}
.main__button-container {
  height: 80%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 5%;
  box-sizing: border-box;
}
.test-user-set {
  position: absolute;
  height: 5%;
  bottom: 0;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
}
.test-user-set button {
  width: 30%;
}
.tariffes_btn_discount_label {
  /* width: 40%;
  height: 2vh; */
  padding: 0.5vh 2vh 0.5vh 2vh;
  position: absolute;
  top: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 2vh;
  background-color: white;
  border-radius: 2vh;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

}
.tariffes_btn_discount_label_text {
  font-weight: 900;
  font-family: 'Inter', sans-serif;
  color: #F95273;
  font-size: 2.5vw;
}
