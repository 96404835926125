.popup {
  border-radius: 13px 13px 0 0;
  max-height: 90%;
  width: 96%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  padding: 0 30px 30px 30px;
  left: 2%;
  bottom: 10px;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.15);
}

.popup-own.hidden {
  display: none;
}

.popup.active {
  height: 100%;
  padding-bottom: 16px;
  z-index: 18;
}
.popup__top-button {
  position: relative;
  width: 100%;
  background-color: transparent;
  border-color: transparent;
  box-sizing: border-box;
  color: inherit;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 50px;
}
.popup__top-button::after {
  content: "";
  width: 60px;
  height: 5px;
  opacity: 1;
  box-sizing: border-box;
  border-radius: 50px;
  position: absolute;
  background-color: var(--violet);
  top: 5px;
  transition: all 0.3s ease;
}
.popup.active .popup__top-button::after {
  opacity: 0;
}
.popup-gift .popup__top-button::after {
  background-color: var(--orange);
}
.popup.active .popup__title {
  font-size: 1.6rem;
  line-height: 1.6rem;
}
.popup__title {
  font-weight: 700;
  transition: all 0.3s ease;
  font-size: 1.4rem;
  text-align: center;
  font-family: "Actay Wide", sans-serif;
  margin: 0;
  color: var(--white);
  user-select: none;
}
.popup-gift {
  color: var(--orange);
  background-color: var(--white);
  padding: 0 30px;
}
.popup-gift__text {
  font-size: 1.3rem;
  line-height: 1.7rem;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}
.gift__popup-text:last-of-type {
  margin-bottom: auto;
}
.popup-gift .popup__title {
  color: var(--orange);
}
.popup__button-icon {
  width: 10px;
  height: 10px;
  transform: rotate(-45deg);
  background-color: transparent;
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  position: absolute;
  left: 10px;
  top: 13px;
  transition: all 0.3s linear;
  opacity: 0;
}
.popup__button-icon.active {
  opacity: 1;
}
.popup__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.6);
  pointer-events: none;
}
.popup__overlay.active {
  pointer-events: all;
}
.popup__text-container_hidden {
  opacity: 0;
  overflow-y: scroll;
}

.popup-referral {
  color: var(--violet);
  background-color: var(--white);
  padding: 0 30px;
}
.popup-referral__text {
  font-size: 1.3rem;
  line-height: 1.7rem;
}

.popup-own {
  color: var(--white);
  background-color: var(--violet);
}

.popup__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 5px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2.1rem;
  position: relative;
  align-items: center;
}

.popup-referral .popup__title {
  color: var(--violet);
}

.popup-tariff {
  color: var(--blue);
  background-color: var(--white);
}

.popup-remote {
  color: var(--orange);
  background-color: var(--white);
}

.popup-remote.disabled {
  color: var(--gray);
}

.popup-remote .popup__title {
  color: var(--orange);
}

.popup__top-button-referral {
  padding-bottom: 10px;
}

.popup-remote .popup__top-button::after {
  background-color: var(--orange);
}

.popup-tariff .popup__title {
  color: var(--blue);
}
.popup-tariff .popup__top-button::after {
  background-color: var(--blue);
}

.popup-own .popup__top-button::after {
  background-color: var(--white);
}
