.menu-button {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  color: var(--white);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 48%;
  width: 47%;
  text-decoration: none;
  padding: 12px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid transparent;
  text-align: start;
  font-family: 'Actay', sans-serif;
}

.menu-button__icon {
  position: absolute;
  top: 12px;
  left: 12px;
  height: 4.5vh;
  max-width: 4.5vh;
  max-height: 4.5vh;
}
.menu-button__title {
  font-weight: 700;
  margin: 0 0 0.5vh 0;
  font-family: 'Actay Wide', sans-serif;
  z-index: 1;
}

.menu-button__text {
  font-size: 1.6vh;
  line-height: 2vh;
  margin: 0;
  z-index: 1;
}
.menu-button__additional-text {
  margin: 0;
  font-size: 1.6vh;
  line-height: 2vh;
  z-index: 1;
}
.menu-button.btn-my-tariff {
  width: 100%;
  height: 19%;
  background-color: var(--dark-blue);
  font-size: 2vh !important;
  line-height: 2.2vh;
}

.menu-button.btn-my-tariff .menu-button__icon  {
  height: 4vh;
}

.menu-button__title.btn-my-tariff {
  font-size: 2.2vh !important;
  line-height: 2.4vh;
}

.menu-button__title.btn-balance {
  font-size: 2.5vh !important;
  line-height: 2.7vh;
}

.menu-button__title.btn-traffic {
  font-size: 2.5vh !important;
  line-height: 2.7vh;
}

.menu-button__title.btn-options {
  font-size: 2.5vh !important;
  line-height: 2.7vh;
}

.menu-button.btn-balance {
  background-color: #ffbe30;
  width: 47%;
  height: 48%;
  font-size: 2.5vh;
}

.menu-button.btn-traffic {
  width: 47%;
  height: 48%;
  background-color: var(--rose);
  font-size: 1.7rem;
}
.menu-button.btn-options {
  width: 47%;
  height: 48%;
  font-size: 1.4rem;
  margin-left: auto;
  background-color: var(--orange);
}
.menu-button.btn-support {
  width: 47%;
  height: 48%;
  background-color: var(--blue);
  margin-left: auto;
  font-size: 1.7rem;
}
.menu-button.btn-options .menu-button__icon {
  width: 30px;
  height: 30px;
}
.menu-button.btn-our-values {
  height: 18%;
  margin-bottom: 2%;
  width: 100%;
  background-color: var(--orange);
}
/* .menu-button.btn-our-values .menu-button__icon {
  height: 40px;
} */
.menu-button.btn-our-values .menu-button__title {
  font-size: 2.8vh;
  line-height: 2.9vh;
}
.menu-button.btn-our-possibilities {
  height: 18%;
  margin-bottom: 2%;
  width: 100%;
  background-color: var(--rose);
}
.menu-button.btn-our-possibilities .menu-button__title {
  font-size: 2.8vh;
  line-height: 2.9vh;
}
/* .menu-button.btn-our-possibilities .menu-button__icon {
  height: 40px;
} */

.menu-button.btn-news {
  height: 100%;
  width: 48%;
  background-color: var(--violet);
}
.menu-button.btn-news .menu-button__title {
  font-size: 2.2vh;
  line-height: 2.4vh;
}

.menu-button.btn-news {
  height: 100%;
  width: 48%;
  background-color: var(--violet);
}
.menu-button__title.btn-news {
  font-size: 2.6rem;
  line-height: 2rem;
}
.menu-button.btn-faq {
  height: 49%;
  width: 49%;
  font-size: 1.8vh;
    line-height: 2vh;
  background-color: var(--blue);
  margin-bottom: 2%;
  margin-left: auto;
}
/* .menu-button.btn-faq .menu-button__icon {
  height: 40px;
} */
.menu-button.btn-no-responce {
  height: 47%;
  width: 49%;
  background-color: #ffbe30;
  margin: auto 0 0 auto;
}
.menu-button__title.btn-no-responce {
  font-size: 2.2vh;
    line-height: 2.4vh;
}
.menu-button.btn-no-responce .menu-button__icon {
  height: 4vh;
}
.btn-our-values .menu-button__text {
  font-size: 1.6vh;
  line-height: 2vh;
}
.btn-our-possibilities .menu-button__text {
  font-size: 1.6vh;
  line-height: 2vh;
}
.btn-faq .menu-button__text {
  font-size: 1.6vh;
  line-height: 2vh;
}
.menu-button__title.btn-referrals {
    font-size: 2.2vh !important;
    line-height: 2.4vh !important;
}

.menu-button__title.btn-support {
  font-size: 2.5vh !important;
  line-height: 2.7vh !important;
}

/* .menu-button.btn-referral {
  background-color: var(--violet);
  height: 26%;
  margin-left: auto;
} */

.menu-button.btn-my-vpn {
  background-color: var(--blue);
  height: 49%;
}
.menu-button__title.btn-my-vpn {
  font-size: 5vh;
  line-height: 5.5vh;
}
.menu-button.btn-not-understand {
  background-color: #ffbe30;
  height: 31%;
  width: 48%;
  margin-left: auto;
}
.menu-button__title.btn-not-understand {
  font-size: 2.8vh;
  line-height: 2.9vh;
}

.menu-button.btn-tariffes {
  background-color: var(--rose);
  height: 20%;
  margin-left: auto;
  width: 100%;
}
.menu-button__title.btn-tariffes {
  font-size: 3.4vh;
  line-height: 3.6vh;
}
/* .menu-button.btn-referral {
  background-color: var(--violet);
  height: 30%;
  margin-left: auto;
  width: 48%;
} */
.menu-button__title.btn-referral {
  font-size: 1.6rem;
  line-height: 1.8rem;
}
.menu-button.btn-gift {
  /* background-color: #ffbe30; */
  background-color: var(--orange);
  height: 31%;
  margin-left: auto;
  width: 48%;
}
.menu-button__title.btn-gift {
  font-size: 2.8vh;
  line-height: 2.9vh;
}

.menu-button.btn-robot {
  background-color: var(--dark-blue);
  height: 31%;
  width: 48%;
  margin-left: auto;
}
.menu-button__title.btn-robot {
  font-size: 2vh;
  line-height: 2.3vh;
}
.menu-button.btn-referrals {
  height: 48%;
  width: 47%;
  background-color: var(--violet);
}
.menu-button__title.btn-referrals {
  font-size: 1.6rem;
  line-height: 1.8rem;
}
.menu-button-circle {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: inherit;
  border-radius: 13px;
  top: 0;
  left: 0;
  z-index: 0;
}
@media screen and (max-height: 650px) {
  /* .menu-button__icon {
    width: 25px !important;
    height: 25px !important;
  } */
}
@media screen and (max-height: 552px) {
  /* .menu-button__icon {
    width: 20px !important;
    height: 20px !important;
  } */
  .menu-button {
    padding: 5px 12px;
  }
}

/* @media screen and (max-height: 650px) {
  .menu-button__title.btn-robot {
    font-size: 1.3rem;
  }
} */

@media (max-height: 690px) {
  /* .menu-button__title {
    font-size: 1.5rem;
  }
  .menu-button__title.btn-my-vpn {
    font-size: 3.3rem;
  }  */
  .menu-button.btn-no-responce {
    height: 43%;
  }
} 

/* @media (max-width: 380px) {
  .menu-button.btn-balance{
    font-size: 1.4rem;
  }
} 

@media (max-width: 320px) {
  .menu-button.btn-balance{
    font-size: 1.2rem;
  }
}  */
