.tariffes__content-own {
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  /* margin-top: 10px; */
}

.own-first .tariffes__content-own {
  padding: 2vh;
}

.tariff-own__speed {
  border: 1px solid var(--blue);
  width: 100%;
  border-radius: 12px;
  font-family: "Actay", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: var(--blue);
  padding: 5px 10px;
  box-sizing: border-box;
  margin-top: 7px;
}
.tariff__content-own_tariffes {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.tariff-own__info {
  width: 85%;
  margin: 0 auto;
  /* display: flex; */
  /* flex-direction: column;
  justify-content: center;
  align-items: center; */
  color: var(--violet);
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: center;
}
.tariff-own__country {
  width: 100%;
}

/* .tariff-own__country {
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} */

.tariff-own__title {
  font-size: 3.9rem;
  font-weight: 700;
  font-family: "Actay Wide", sans-serif;
  color: var(--violet);
  margin-bottom: 4.4rem;
  margin-top: 2rem;
}

.tariff-own__country-box {
  width: 100%;
  margin-bottom: 4.2rem;
}

.tariff-own__country-box__row {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.tariff-own__country-item {
  width: 48%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--violet);
  border: 1.5px solid var(--violet);
  border-radius: 12px;
  font-size: 1.3rem;
  font-weight: 700;
}

.tariff-own__country-item.highlighted {
  background-color: var(--violet);
  color: var(--white);
}

.tariff-own__amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 800;
  font-size: 1.3rem;
  width: 80%;
  margin: 0 auto;
  background-color: var(--violet);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  color: var(--white);
  min-height: 5rem;
  padding: 0 15px;
}

.tariff-own__amount.own-country {
  width: 85%;
}

/* .tariff-own__amount.tariffes-amount {
  margin-bottom: 5px;
} */

.tariff-own__amount-full {
  text-decoration: line-through;
  margin-left: 7px;
}

.tariff-own__amount-full.hidden {
  display: none;
}

.tariff-own__amount.hidden {
  display: none;
}

.tariff-own__options {
  width: 90%;
  margin: 10px auto 0 auto;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 13px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding-top: 10px;
  background-color: var(--violet);
  color: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  font-size: 1.4rem;
  /* margin-bottom: 7px; */
}

.tariff-own__options span {
  margin-bottom: 3px;
}

.tariff-own__btn-container {
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
}
.motion-div-style {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.tariffes-own-fields {
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}
.tariff-own__btn-container.persons {
  margin-top: 2vh;
}

&::-webkit-scrollbar:horizontal {
  height: 0;
  width: 0;
  display: none;
}

&::-webkit-scrollbar-thumb:horizontal {
  display: none;
}

.tariff-own__error {
  width: 100%;
  font-size: 1.1rem;
  color: red;
  text-align: center;
  height: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.tariff-own__test-toggle {
  display: flex;
  width: 90%;
  font-family: "Actay", sans-serif;
  background-color: #f8f9fe;
  border-radius: 16px;
  padding: 10px 16px 11px 23px;
  margin-bottom: 23px;
  margin-top: 1rem;
  align-items: center;
  justify-content: space-around;
}

.tariff-own__test-toggle__desc {
  width: 70%;
  color: #aeaeae;
}

.tariff-own__test-toggle__desc.checked {
  color: var(--violet);
}

.tariff-own__test-toggle__desc-title {
  margin-top: 10px;
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 2.1rem;
}

.tariff-own__test-toggle__desc-text {
  font-size: 1rem;
}

.tariff-own__test-toggle__motion {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 56px;
  height: 30px;
  background: grey;
  border-radius: 50px;
  padding-left: 2px;
  padding-right: 1px;
}

.tariff-own__test-toggle__motion.own {
  background: var(--violet);
}

.tariff-own__test-toggle__motion-btn {
  width: 27px;
  height: 27px;
  background: #fff;
  border-radius: 90px;
}

.tariff-own__country-item.disabled {
  pointer-events: none;
  cursor: not-allowed;
  background-color: var(--gray);
  color: var(--white);
}

.tariff-own_conditions-text {
  color: var(--violet);
  font-size: 1.1rem;
  margin-top: 5px;
  text-align: center;
}

@media screen and (max-height: 640px) {
  .tariff-own__test-toggle {
    padding: 5px 11px 6px 18px;
  }

  .tariff-own__test-toggle__desc-title {
    font-size: 1.3rem;
    margin-top: 5px;
  }

  .tariff-own__test-toggle__motion {
    min-width: 51px;
    height: 27px;
  }

  .tariff-own__test-toggle__motion-btn {
    width: 24px;
    height: 24px;
  }

  .tariff-own__title {
    font-size: 3.2rem;
  }
}

@media screen and (max-height: 600px) {
  .tariff-own__amount.own-country {
    min-height: 4rem;
  }
}

@media screen and (max-height: 650px) and (max-width: 370px) {
  .tariff-own__amount.own-country {
    min-height: 4rem;
  }
  .tariff-own__title {
    font-size: 3rem;
  }
}

@media screen and (max-height: 640px) and (max-width: 370px) {
  .tariff-own__country-item {
    min-height: 40px;
  }
}

@media screen and (max-height: 610px) and (max-width: 370px) {
  .tariff-own__title {
    font-size: 2.8rem;
  }

  .tariff-own__options {
    font-size: 1.2rem;
  }
}

@media screen and (max-height: 570px) {
  .tariff-own__title {
    font-size: 3rem;
  }

  .tariff-own__amount.own-country {
    min-height: 4rem;
  }
}

@media screen and (max-height: 650px) {
  .tariffes-own-fields {
    justify-content: flex-start;
  }
}

@media screen and (max-height: 585px) {
  .tariff-own__error {
    font-size: 1.1rem;
    height: 15px;
  }
}

@media screen and (max-height: 600px) {
  .tariffes-template__content.own {
    /* height: 50%; */
  }
}

@media screen and (min-height: 600px) and (max-height: 700px) {
  .tariffes-template__content.own {
    /* height: 80% !important; */
  }
}

@media screen and (min-height: 600px) and (max-height: 700px) {
  .tariffes-template__content.own:not(.own-country) {
    /* height: 80% !important; */
  }
}

@media screen and (min-height: 700px) and (max-height: 1000px) {
  .tariffes-template__content.own:not(.own-country) {
    height: 85% !important;
  }
}
