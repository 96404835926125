.options {
  padding: 16px 16px 45px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  position: relative;
}
.options__button-box {
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.options__content {
  box-sizing: border-box;
  padding-top: 5%;
  width: 100%;
  height: 100%;
}
.options__item {
  position: relative;
  width: 100%;
  background-color: var(--orange);
  padding: 14px 16px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  color: var(--white);
  margin-bottom: 10px;
  cursor: pointer;
}
.options__item-arrow {
  width: 10px;
  height: 10px;
  box-sizing: border-box;
  transform: rotate(45deg);
  background-color: transparent;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  position: absolute;
  right: 10px;
  top: 45%;
  border-color: var(--white);
}
.options__item-arrow.active {
  transform: rotate(225deg);
  top: 0;
}
.options__item-arrow-wrapper {
  padding: 20px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.options__item-title {
  margin: 0 0 5px 0;
  font-family: 'Inter', sans-serif;
  font-size: 1.8rem;
  line-height: 2rem;
  font-weight: 700;
}
.options__item-title.communicate {
  font-size: 1.6rem;
}
.options__item.with-popup {
  z-index: 5;
}
.options__item-button-text {
  margin: 0;
  text-decoration: underline;
  font-family: 'Inter', sans-serif;
  font-size: 1.3rem;
  width: 30%;
}
.options__item-text {
  font-size: 1.4rem;
  font-weight: 400;
  width: 90%;
  margin: 0;
}
.options__item-link{
  text-decoration: underline;
}
.options__item-location-box {
  margin-top: 17px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.options__country-item {
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  background-color: var(--white);
  border-radius: 50px;
  padding: 6px 12px;
  box-sizing: border-box;
  color: var(--blue);
  font-weight: 700;
  margin-right: 10px;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.options__country-item.device {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.options__country-item.active {
  color: var(--white);
  background-color: var(--blue);
}
.options__country-current {
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  border-radius: 50px;
  padding: 6px 12px;
  box-sizing: border-box;
  color: var(--white);
  background-color: var(--blue);
  font-weight: 700;
  margin-right: 10px;
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.options__country-current.hidden {
  display: none;
}
.options__overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 3;
  display: none;
}
.options__overlay.active {
  display: block;
}
.options__item-checkbox {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  padding: 20px;
}
.options__popup-close-button {
  background-color: transparent;
  border: none;
  padding: 15px;
  position: absolute;
  top: -5px;
  right: 0;
}
.options__popup-text {
  font-size: 1.2rem;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  margin: 0;
}
.options__popup {
  padding: 20px;
  width: 100%;
  border-radius: 16px;
  display: none;
  position: absolute;
  left: 0;
  top: 130%;
  background-color: var(--orange);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  z-index: 5;
}
.options__popup.smart::before,
.options__popup.care::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid #ff824b;
  position: absolute;
  bottom: 99%;
  left: 5%;
}
.options__popup.care {
  width: 80%;
}
.options__popup.active {
  display: block;
  padding-right: 35px;
}
.options__popup.communicate::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid #ff824b;
  position: absolute;
  bottom: 99%;
  right: 5%;
}
.options__popup-button-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}
.option__complete-text {
  font-family: 'Actay Wide', sans-serif;
  color: var(--white);
  margin: 0 0 24px 0;
  font-weight: 700;
  font-size: 2rem;
}
.option__complete-text-big {
  font-family: 'Actay Wide', sans-serif;
  font-size: 4rem;
  line-height: 4.3rem;
  color: var(--white);
}
.options__complete-content {
  width: 90%;
  margin-top: 30%;
}
.options__complete-content.no-margin {
  margin-top: 0;
}
.options__complete-tips {
  font-size: 1.4rem;
  color: var(--white);
}

.options__button-text {
  width: 80%;
  color: var(--orange);
  margin-bottom: 10px;
  text-align: center;
}
