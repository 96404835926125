.success {
  display: flex;
  flex-direction: column;
  padding: 20% 10% 20% 10%;
  align-items: center;
  color: var(--white);
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
}
.success__text {
  font-family: 'Actay Wide', sans-serif;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.4rem;
}
.success__text-secondary {
  font-family: 'Actay', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7rem;
}
.success__button-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.success__app-button {
  width: 100%;
  border: 2px solid #fff;
  background-color: var(--white);
  color: var(--dark-blue);
}
.success__app-button-secondary {
  color: var(--white);
  background-color: transparent;
}
