.tariffes-template-popup {
  background: rgba(239, 238, 244, 0.74);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20;
  pointer-events: none;
}
.tariffes-template-popup.active {
  pointer-events: all;
}
.tariffes-template-popup__info {
  width: 80%;
  box-sizing: border-box;
  font-size: 1.2rem;
  line-height: 1.2rem;
  padding: 24px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 16px;
  margin-bottom: 30px;
}
.tariffes-template-popup__info.gift-example {
  height: 80%;
  max-width: fit-content;
  padding: 0;
  margin-bottom: 0;
  /* position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 80%;
  height: 80%; */
}

.tariffes-template-popup__info-button {
  background-color: transparent;
  height: 30px;
  border: none;
  color: inherit;
  text-align: start;
  padding: 0;
  margin: 0;
  font-family: 'Actay Wide', sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2rem;
  position: relative;
}
.tariffes-template-popup__info-button.gift-example {
  display: none;
}
.tariffes-template-popup__info-subtitle {
  margin: 10px 0 0 0;
  font-family: 'Actay Wide';
  font-weight: 700;
}
.tariffes-template-popup__info-text {
  font-family: 'Inter', sans-serif;
  margin: 0;
}
.tariffes-template-popup__info.bonus {
  color: var(--violet);
}

.tariffes-template-popup__info.fit {
  color: var(--orange);
  margin: 0;
}
.tariffes-template-popup__info.nolimit {
  color: var(--rose);
  margin-bottom: 0;
}

.tariffes-template-popup__info.fit-more {
  background: linear-gradient(180deg, #FF824B 0%, #FFBE30 100%);
  color: #fff;
}

.tariffes-template-popup__info.nolimit-more {
  background: linear-gradient(180deg, #FFBE30 0%, #F95273 100%);
  color: #fff;
}

.tariffes-template-popup__info.own-more {
  background: linear-gradient(180deg, #7472EB 0%, #F95273 100%);
  color: var(--white);
}

.tariffes-template-popup__info-button-arrow {
  width: 10px;
  height: 10px;
  transform: rotate(45deg) translateY(-50%);
  background-color: transparent;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  position: absolute;
  right: 10px;
  top: 50%;
}
.tariffes-template-popup__info-button-arrow.free {
  border-color: var(--violet);
}
.tariffes-template-popup__info-button-arrow.fit {
  border-color: var(--orange);
}
.tariffes-template-popup__info-button-arrow.nolimit {
  border-color: var(--rose);
}
.tariffes-template-popup__link {
  text-decoration: underline;
  cursor: pointer;
}

.tariffes-template-popup__info-button.nolimit-more {
  display: none;
}
.tariffes-template-popup__info-button.fit-more {
  display: none;
}
