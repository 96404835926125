.help {
  width: 100%;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.help__content {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.help__button-box {
  height: 39%;
  padding-bottom: 2%;
  display: flex;
  flex-direction: column;
  column-gap: 2%;
  flex-wrap: wrap;
}
