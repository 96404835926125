.news {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 45px 90px 45px;
  box-sizing: border-box;
  color: var(--white);
}
.news__button-box {
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.news__text {
  font-size: 2.4rem;
  font-weight: 700;
  font-family: 'Actay Wide', sans-serif;
}
