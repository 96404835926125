.gift {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 18px 16px 61px 16px;
  box-sizing: border-box;
  font-family: 'Actay Wide', sans-serif;
}

.gift__explanation {
  color: var(--white);
  font-size: 1.1rem;
  line-height: 1.6rem;
  margin-top: 10px;
  margin-bottom: 30px;
}
.gift__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.gift__title {
  max-width: 285px;
  align-self: flex-start;
  margin: 40px auto 20px 4%;
  font-size: 3.8rem;
  line-height: 4.3rem;
  color: var(--white);
}
.gift__subtitle {
  font-size: 2.4rem;
  line-height: 3rem;
  margin: 0 auto auto 4%;
  align-self: flex-start;
  color: var(--white);
}
.gift__form {
  width: 95%;
  height: 80%;
  margin-bottom: 4.5rem;
  overflow-y: auto;
  border-radius: 15px;
}

.gift__user-selection {
  width: 90%;
  height: 45px;
  background-color: var(--orange);
  margin-bottom: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  padding: 12px;
  padding-top: 15px;
  box-sizing: border-box;
}
.gift__user-selection.colored {
  background: linear-gradient(180deg, #ff824b 0%, #ffbe30 100%);
}
.gift__user-selection-placeholder {
  margin: 0;
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.7rem;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.gift__submit-button {
  width: 90%;
  height: 48px;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 12px 16px;
  border: none;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.5rem;
  color: var(--orange);
}
.gift__submit-button.margin-top {
  margin-top: 15px;
}
.gift__back-button {
  min-height: 40px;
  font-family: 'Actay', sans-serif;
  font-size: 1.4rem;
  height: 40px;
  text-decoration: none;
  color: var(--white);
  align-self: flex-start;
  align-items: center;
  padding: 0 20px;
  position: absolute;
  outline: none;
  border: 1px solid transparent;
  background-color: transparent;
  width: calc(100% - 40px);
  justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  box-sizing: border-box;
  left: 20px;
  top: 2vh;
  z-index: 6;
}
.gift__back-button-title {
  font-size: 1.6rem;
  line-height: 1.9rem;
  text-align: center;
  justify-self: center;
  width: 100%;
}
.gift__back-button-text {
  text-align: left;
  font-size: 1.2rem;
  line-height: 1.2rem;
}
.gift__popup-link {
  text-decoration: underline;
}
.gift__back-button-corner {
  width: 10px;
  height: 10px;
  transform: rotate(-45deg);
  background-color: transparent;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  position: absolute;
  left: 0;
  transition: all 0.3s linear;
}
.gift__content.faded {
  opacity: 0;
}

@media screen and (max-height: 650px) {
  .gift__popup-text {
    margin: 0 0 10px 0;
  }
  .gift__popup-text:first-of-type {
    margin-top: 20px;
  }
  .gift__popup-text:last-of-type {
    margin-bottom: auto;
  }
}

@media (max-height: 690px) {
  .gift__title {
    margin: 20px auto 20px 4%;
    font-size: 3rem;
    line-height: 3rem;
  }

  .gift__form {
    margin-bottom: 1rem;
  }
}

@media screen and (max-height: 715px) {
  .gift__submit-button {
    height: 40px;
    padding: 8px 16px;
  }
}

@media screen and (max-height: 535px) {
  .gift__title {
    margin: 10px auto 10px 4%;
  }
  .gift__submit-button {
    height: 30px;
    padding: 6px 16px;
  }
}
