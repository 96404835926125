.possibilities {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding: 16px 0;
  box-sizing: border-box;
}
.possibilities__button-box {
  display: flex;
  flex-direction: column;
  min-height: 20%;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px 0;
  width: 90%;
}
