.form-label-discount {
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  line-height: 1rem;
  padding: 4px 12px;
  border-radius: 13px;
  background-color: var(--white);
  font-weight: 700;
  box-sizing: border-box;
  margin-left: 5px;
  box-shadow: 1px -1px 4px rgba(0, 0, 0, 0.25);
}
.form-label-discount.fit {
  color: var(--orange);
}
.form-label-discount.nolimit {
  color: var(--rose);
}

.form-label-discount.nolimit-list {
  color: var(--rose);
}

.form-label-discount.own {
  color: var(--violet)
}

.form-label-discount.own-list {
  color: var(--violet)
}

.form-label-discount.gift-discount {
  color: var(--orange);
}

.form-label-discount.recomended {
  color: var(--rose);
  box-shadow: 0px 4px 4px rgba(249, 82, 115, 0.8);
}
