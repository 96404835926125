.burger-menu {
  position: absolute;
  z-index: 16;
  pointer-events: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
}
.burger-menu.active {
  pointer-events: all;
}
.burger-menu__background {
  background-color: var(--white);
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
}
.burger-menu__button {
  width: 40px;
  height: 40px;
  border: none;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: absolute;
  z-index: 5;
  top: 2vh;
  left: calc(90% - 20px);
  transform: translateZ(1px);
  pointer-events: all;
  padding: 0;
  cursor: pointer;
}
.burger-menu-nav__support-text {
  font-size: 1.4rem;
  color: var(--blue);
  text-align: right;
  margin-right: 16px;
  justify-self: flex-end;
}

.burger-menu__button.remote-burger {
  top: 2vh;
}

/* .burger-menu__button.own-country {
  top: 0px;
}

.burger-menu__button.own-first {
  top: 12px;
} */

/* @media screen and (min-height: 707px) {
  .burger-menu__button.own-country {
    top: 10px;
  }
}

@media screen and (max-height: 707px) {
  .burger-menu__button.own-country {
    top: 12px;
  }
} */

/* @media screen and (max-height: 795px) {
  .burger-menu__button.own_second {
    top: 23px;
  }
}

@media screen and (max-height: 785px) {
  .burger-menu__button.own_second {
    top: 21px;
  }
}

@media screen and (max-height: 780px) {
  .burger-menu__button.fit {
    top: 25px;
  }

  .burger-menu__button.own_second {
    top: 17px;
  }
}

@media screen and (max-height: 775px) {
  .burger-menu__button.own_second {
    top: 14px;
  }
}

@media screen and (max-height: 769px) {
  .burger-menu__button.own_second {
    top: 11px;
  }
}

@media screen and (max-height: 760px) {
  .burger-menu__button.fit {
    top: 10px;
  }
} */

/* @media screen and (max-height: 704px) {

  .burger-menu__button.nolimit-burger {
    top: 25px;
  }
}

@media screen and (max-height: 693px) {
  .burger-menu__button.nolimit-burger {
    top: 20px;
  }
}

@media screen and (max-height: 683px) {

  .burger-menu__button.nolimit-burger {
    top: 15px;
  }
}

@media screen and (max-height: 673px) {

  .burger-menu__button.nolimit-burger {
    top: 10px;
  }
} */

@media screen and (max-height: 570px) {
  .burger-menu__button.remote-burger {
    top: 10px;
  }
}

@media screen and (max-height: 560px) {
  .burger-menu__button.remote-burger {
    top: 8px;
  }
}

@media screen and (max-height: 550px) {
  .burger-menu__button.fit {
    top: 30px;
  }
}

@media screen and (max-height: 553px) {
  .burger-menu__button.own-country {
    top: 30px;
  }
}
