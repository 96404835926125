.payments-email-form-button {
  color: var(--white);
  background-color: var(--dark-blue);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  width: 90%;
  height: 48px;
  box-sizing: border-box;
  font-family: 'Actay Wide', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  transition: all 0.3s linear;
  border: none;
  margin-top: auto;
}
.payments-email-form-button.disabled {
  background-color: var(--gray);
}
.payments-email-form__input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  position: relative;
}
.payments-email-form__input-title {
  font-size: 2rem;
  font-weight: 700;
  font-family: 'Actay Wide', sans-serif;
  margin-bottom: 15px;
}
.payments-email-form {
  width: 100%;
  margin-top: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.payments-email-form__input {
  width: 100%;
  height: 48px;
  font-family: 'Inter', sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 12px;
  border: 2px solid #064b72;
  color: var(--dark-blue);
}
.payments-email-form-text {
  width: 80%;
  font-size: 1.4rem;
  font-weight: 400;
  text-align: center;
  margin: 0;
}
.payments-email-form__icon {
  position: absolute;
  bottom: 15px;
  right: 16px;
  opacity: 0;
  transition: opacity 0.3s linear;
}
.payments-email-form__icon.enabled {
  opacity: 1;
}
