.error-page {
  color: var(--white);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--gray);
}
.error-page__image {
  width: 154px;
  height: 154px;
  margin: 40% 0 10% 0;
}
.error-page__title {
  font-family: 'Actay Wide', sans-serif;
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.4rem;
  text-align: center;
  max-width: 70%;
  margin: 0;
}
.error-page__text {
  font-family: 'Actay', sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.8rem;
  text-align: center;
  margin: 10px 0 0 0;
}
.error-page__link {
  text-decoration: underline;
  cursor: pointer;
}
