.gift-success {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 45px 64px 45px;
  box-sizing: border-box;
  color: var(--white);
}
.gift-success__content {
  font-family: 'Actay Wide', sans-serif;
  font-weight: 700;
  margin: auto 0;
}
.gift-success__title {
  font-size: 4rem;
  line-height: 4.3rem;
}
.gift-success__text {
  font-size: 2rem;
  line-height: 2.5rem;
}
