.intro {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vh 35px 7vh 35px;
  box-sizing: border-box;
}
.intro__progress {
  width: 304.5px;
  gap: 5.25px;
  display: flex;
  justify-content: center;
  margin: 20px 0 20px 0;
}
.intro__progress-item {
  display: block;
  width: 8px;
  height: 8px;
  background-color: #e8e9f1;
  border-radius: 7px;
  transition: background-color 0.5s ease;
}
.intro__back-button {
  box-sizing: border-box;
  height: 10%;
  width: 100%;
  position: relative;
  background-color: transparent;
  text-align: start;
  padding: 0 0 0 25px;
  border: none;
  color: var(--blue);
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  line-height: 1.2rem;
  transition: all 0.2s linear;
  opacity: 1;
}
.intro__back-button-arrow {
  width: 10px;
  height: 10px;
  transform: rotate(-45deg);
  background-color: transparent;
  border-top: 2px solid #348ff3;
  border-left: 2px solid #348ff3;
  position: absolute;
  left: 0;
}
.intro__back-button.hidden_nav {
  opacity: 0;
}
.intro__progress-item.active {
  background-color: var(--blue);
}
.intro__image-container {
  min-width: 187px;
  box-sizing: border-box;
  min-height: 187px;
  margin: 2% auto 10% auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.intro__image-container.margin-top {
  margin: 20.7% auto 10% auto;
}
.intro__image {
  z-index: 1;
}
.intro__image-sector {
  z-index: 2;
  opacity: 0;
  position: absolute;
  transition: opacity 1s ease;
  top: 0;
  left: 0;
}
.intro__image-sector.active {
  opacity: 1;
}
.intro__text-container {
  font-family: "Actay", sans-serif;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 287px;
  color: var(--blue);
  text-align: center;
  letter-spacing: -0.4099999964237213px;
  margin-bottom: auto;
}
.intro__title {
  font-weight: 700;
  font-size: 3vh;
  line-height: 3.3vh;
  margin: 0;
  font-family: "Actay Wide", sans-serif;
}
.intro__title_colored {
  color: #ffbe30;
}
.intro__text {
  font-weight: 400;
  font-size: 1.7vh;
  line-height: 2.1vh;
}

/* @media screen and (max-height: 645px) {
  .intro__text {
    font-size: 1.3rem;
    line-height: 1.4rem;
  }

  .intro__title {
    font-size: 2.3rem;
    line-height: 2.1rem;
  }
} */

/* @media screen and (max-height: 640px) {
  .intro__image-container {
    margin: 7% auto 5% auto;
  }
} */
@media screen and (max-height: 552px) {
  .intro__text-container {
    min-height: auto;
  }
  .intro__image-container {
    margin: 3% auto 5% auto;
  }
}
