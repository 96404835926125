.remote__popup {
    background: rgba(239, 238, 244, 0.74);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 20;
    pointer-events: none;
  }

  .remote__popup.active {
    pointer-events: all;
  }

  .remote__popup__info {
    width: 80%;
    box-sizing: border-box;
    font-size: 1.2rem;
    line-height: 1.2rem;
    padding: 24px;
    display: flex;
    flex-direction: column;
    background: var(--orange);
    border-radius: 16px;
    margin-bottom: 30px;
  }

  .remote__popup__info-button {
    background-color: transparent;
    height: 30px;
    border: none;
    color: inherit;
    text-align: start;
    padding: 0;
    margin: 0;
    font-family: 'Actay Wide', sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2rem;
    position: relative;
  }