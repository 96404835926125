.app {
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 600px;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden;
  background-color: transparent;
  transition: background-color 0.2s linear;
  z-index: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.app-gift,
.app-options,
.app-optionscomplete,
.app-message-us,
.app-gift-success {
  background-color: #ffbe30;
}
.app-referral
 {
  background-color: var(--violet);
}
.app-faq {
  background-color: var(--blue);
}
.app-balance,
.app-values {
  background-color: var(--orange);
}
.app-traffic,
.app-possibilities {
  background-color: var(--rose);
}
.app-support.nolimit {
  background-color: var(--blue);
}
.app-support,
.app-news {
  background-color: var(--gray);
}
.app-subscription,
.app-success {
  background-color: var(--dark-blue);
}
.app-payment {
  background-color: var(--white);
}
@media screen and (max-height: 648px) {
  .app {
    overflow: hidden;
  }
}
